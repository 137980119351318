import React, { useState } from 'react';
import cn from 'classnames';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [cartOpen, setCartOpen] = useState(false);

    const toggleMenu = (e) => {
        if (e) {
            e.preventDefault();
        }

        // hide menu on mobile on click item
        const winWidth = window.innerWidth;
        if (winWidth < 1024) {

            // toggle state
            setMenuOpen(!menuOpen);
        }
    };

    const toggleCart = () => {
        // toggle state
        setCartOpen(!cartOpen);
    }

    // get lang labels
    const menuLabels = window.labels.menu;
    const cartLabels = window.labels.cart;

    // get cart info
    const { cart } = window;

    const burgerClassName = cn('burger', {
        'burger--open': menuOpen,
    });

    const mobileMenuClassName = cn('header__mobile-menu', {
        'header__mobile-menu--open': menuOpen,
    });

    const shoppingCartClassName = cn('shopping-cart', {
        'shopping-cart--open': cartOpen,
    });

    const menuItems = [
        '/',
        '/products/bikes',
        '/products/spare-parts',
        '/products/accessories',
    ];

    return (
        <header className="header">
            <div className="header__bar">
                <div className="container header__container">
                    <a href="/" className="header__logo-link">
                        <img className="header__logo" src="/images/logo-white.png" />
                    </a>

                    <div className="header__menu-holder">
                        <a className={burgerClassName} href="#" onClick={toggleMenu}>
                            <span className="burger__slice burger__slice--1"></span>
                            <span className="burger__slice burger__slice--2"></span>
                            <span className="burger__slice burger__slice--3"></span>
                        </a>
                        <button className="shopping-cart__toggle" onClick={toggleCart}>
                            {!!(cart && cart.amount) && <span className="shopping-cart__amount">{cart.amount}</span>}
                            <i className="icon-cart shopping-cart__icon"></i>
                        </button>
                        <ul className="header__menu">
                            {Object.keys(menuLabels).map((key, i) => {
                                const item = menuLabels[key];
                                const currentUrl = window.location.href;
                                const className = cn('header__link', {
                                    'header__link--active': (currentUrl.indexOf(key) > -1)
                                });

                                return (
                                    <li key={i} className="header__item">
                                        <a
                                            href={menuItems[i]}
                                            className={className}
                                        >
                                            {item}
                                        </a>
                                    </li>
                                );
                            })}
                              <li className="header__item header__item-button">
                                <a
                                    href={'https://forms.gle/KZwRg5CRbNXzMTrJ9'}
                                    className={'header__link header__link--button btn btn--white'}
                                    target={'_blank'}
                                >
                                    Test Drive
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <ul className={mobileMenuClassName}>
                {Object.keys(menuLabels).map((key, i) => {
                    const item = menuLabels[key];
                    const currentUrl = window.location.href;
                    const className = cn('header__link', {
                        'header__link--active': (currentUrl.indexOf(key) > -1)
                    });

                    return (
                        <li key={i} className="header__item">
                            <a
                                href={menuItems[i]}
                                className={className}
                            >
                                {item}
                            </a>
                        </li>
                    );
                    
                })}
                <li className="header__item header__item-button">
                    <a
                        href={'https://forms.gle/KZwRg5CRbNXzMTrJ9'}
                        className={'header__link header__link--button btn btn--white'}
                        target={'_blank'}
                    >
                        Test Drive
                    </a>
                </li>
            </ul>
            <div className={shoppingCartClassName}>
                <div className="shopping-cart__overlay" />
                <div className="shopping-cart__box">
                    <div className="shopping-cart__header">
                        <h3>Cart</h3>
                        <button className="shopping-cart__close-btn icon-cross" onClick={toggleCart} />
                    </div>
                    <ul className="shopping-cart__products">
                        {(!cart) && <p className="shopping-cart__empty-message">{cartLabels.empty}</p>}
                        {(cart && cart.products) && Object.keys(cart.products).map((key, i) => {
                            const product = cart.products[key];
                            return (
                                <li key={i} className="shopping-cart__item">
                                    <a href={`/product/${product.id}`} className="shopping-cart__product-link">
                                        <img className="shopping-cart__photo" src={product.photo} />
                                        <div className="shopping-cart__content">
                                            <div>
                                                <h3 className="shopping-cart__product-title">{product.qty}x {product.name}</h3>
                                                {Object.keys(product.options).map((key) => {
                                                    const option = product.options[key];
                                                    return (
                                                        <p key={option.id} className="shopping-cart__option">- {option.type == 'color' ? option.name : option.value}</p>
                                                    );
                                                })}
                                            </div>
                                            <span className="shopping-cart__price">&euro; {product.total_price_inc}</span>
                                        </div>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="shopping-cart__bottom">
                        <span className="shopping-cart__total">{cartLabels.total}: &euro; {cart ? cart.total_inc : 0}</span>
                        <a className="button button--white shopping-cart__button" href="/cart">
                            {cartLabels.to_cart}
                            <i className="icon-arrow-right button__icon button__icon--right" />
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;

import React from 'react';
import ReactDOM from 'react-dom';

// components
import Header from './components/header/Header.jsx';
import ProductImages from './components/product/Images.jsx';

// render header
const reactHeader = document.getElementById('react-header');
if (ReactDOM.findDOMNode(reactHeader)) {
    ReactDOM.render(
        <Header />,
        reactHeader,
    );
}

// render product-images
const reactProductImages = document.getElementById('react-product-images');
if (ReactDOM.findDOMNode(reactProductImages)) {
    ReactDOM.render(
        <ProductImages />,
        reactProductImages,
    );
}

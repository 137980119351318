import React, { useState, useRef } from 'react';
import SlickSlider from 'react-slick';

const Images = () => {
    const [sliderIndex, setSliderIndex] = useState(0);
    const imagesRef = useRef();
    const thumbsRef = useRef();

    const handleIndexChange = (i) => {
        setSliderIndex(i);

        imagesRef.current.slickGoTo(i);
        thumbsRef.current.slickGoTo(i);
    };

    const { media } = window;
    const sliderSettings = {
        className: 'images',
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slickGoTo: sliderIndex,
        afterChange: handleIndexChange,
    };

    let slidesToShow = 5;
    if (media.length < 5) {
        slidesToShow = media.length;
    }

    const navSliderSettings = {
        className: 'images images--thumbs',
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        centerMode: false,
        slickGoTo: sliderIndex,
    };

    return (
        <div className="images__holder">
            <SlickSlider ref={imagesRef} {...sliderSettings}>
                {media && media.map((image) => {
                    const altName = `product ${image.id}`;
                    return (
                        <img
                            key={image.id}
                            className="images__image"
                            src={image.url}
                            alt={altName}
                        />
                    );
                })}
            </SlickSlider>
            <SlickSlider ref={thumbsRef} {...navSliderSettings}>
                {media && media.map((image, i) => {
                    const altName = `product ${image.id}`;
                    return (
                        <img
                            key={image.id}
                            className="images__thumb"
                            src={image.url}
                            alt={altName}
                            onClick={() => handleIndexChange(i)}
                        />
                    );
                })}
            </SlickSlider>
        </div>
    );
}

export default Images;
